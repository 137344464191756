<script>
import { h } from 'vue';

import DsAddressFieldDawa from './DsAddressFieldDawa.vue';
import DsAddressFieldJix from './DsAddressFieldJix.vue';

export default {
    emits: ['update:modelValue', 'dawa-selected'],
    setup(props, { attrs, emit }) {
        const component = !/dk$/.test(window.Stash.common.site)
            ? DsAddressFieldJix
            : DsAddressFieldDawa;
        return () => h(component, {
            ...attrs,
            'onUpdate:modelValue': (value) => emit('update:modelValue', value),
            'onDawaSelected': (value) => emit('dawa-selected', value),
        });
    },
};
</script>
