import $ from 'jquery';

const defaultOptions = {
    valueKey:        'id',
    params:          {},
    textFormat:      '{{text}}',
    selectionFormat: null,
    customText:      false,
    queryKey:        'query'
};

function format(template, data) {
    return template.replace(/\{\{([a-zA-Z_][a-zA-Z0-9_]*)\}\}/g, (_, key) => {
        return Object.prototype.hasOwnProperty.call(data, key) ? $.trim(data[key]) : '';
    });
}

function handleResults(obj, options) {
    return {
        id:   obj[options.valueKey],
        text: format(options.textFormat, obj),
        obj:  obj,
    };
}

/**
 * Sets up a simple autocompleter.
 *
 * @param options An object describing how the autocompleter should behave.
 * The following options are available:
 *     - url A string containing the URL to which the requests are send.
 *     - transformResponse An optional function that takes the response data
 *       and returns an array of autocomplete results. If not specified the
 *       results will be taken from the `result` key on the response object.
 *     - queryKey The key that should hold the query when sending a request.
 *       By default queryKey is 'query'
 *     - params Additional parameters that should be set when making a request.
 *     - valueKey The key that is used to get the value of a selected item.
 *       By default valueKey is 'id'.
 *     - textFormat A string describing how the data should be formated in the
 *       dropdown. Each occurence of '{{key}}' will be replaced by data[key],
 *       where data is the object returned by the server. By default textFormat
 *       is '{{text}}'.
 *     - selectionFormat A string describing how the data should be formated
 *       when selected. It behaves as the textFormat option. If selectionFormat
 *       is not supplied then textFormat will be used instead.
 *     - customText A boolean representing whether it should be possible to
 *       select custom text, i.e. text not returned by the request.
 *     - multiple A boolean representing whether multiple selections should be
 *       allowed. The default is to check the element.
 */
export default function simpleAutocompleteConfig(element, userOptions = {}) {
    const options = {
        multiple: element ? element.multiple : false,
        ...defaultOptions,
        ...(element ? $(element).data() : {}),
        ...userOptions,
    };

    let q;
    const select2_options = {
        minimumInputLength: options.initselect !== undefined ? 0 : 1,
        multiple: options.multiple,
        ajax: {
            url:      options.url,
            dataType: 'json',
            delay:    250,
            cache:    true,
            data: (params) => {
                const query = { ...options.params };
                query[options.queryKey] = params.term;
                q = params.term ? params.term.trim().toLowerCase() : "";
                return query;
            },
            processResults: (data) => {
                if (q.length === 0 && options.initselect !== undefined) {
                    return { results: options.initselect.map((obj) => handleResults(obj, options)) };
                }
                let result;
                if (options.transformResponse) {
                    result = options.transformResponse(data);
                } else {
                    result = data.result;
                }
                return { results: result.map((obj) => handleResults(obj, options)) };
            }
        }
    };

    if (options.selectionFormat) {
        select2_options.templateSelection = (data) => {
            return data.obj
                ? format(options.selectionFormat, data.obj)
                : data.text;
        };
    }

    if (options.customText) {
        select2_options.tags = true;
        select2_options.createTag = (query) => {
            const trimmed = query.term.trim();
            if (trimmed !== '' && $(element).select2('data').every(function(el) {
                return el.text.toLowerCase().localeCompare(trimmed.toLowerCase()) !== 0;
            })) {
                return { id: trimmed, text: trimmed };
            }
        };
    }
    return select2_options;
}
