<script setup>
import { ref, inject, watch, nextTick } from 'vue';
import bootbox from 'bootbox';

import { getGeolocation, reverseGeocodeJIX } from 'JIX/geo/geolocation.js';
import Select2 from 'JIX/components/Select2.vue';
import SvgIcon from 'JIX/components/SvgIcon.vue';
import simpleAutocompleteConfig from 'JIX/select2/simple_autocomplete.js';

import i18n from './DsAddressField.i18n.js';

import 'icons/geolocation.svg';

defineOptions({
    inheritAttrs: false,
});

const props = defineProps({
    modelValue: {
        type: String,
        default: "",
    },
    disableGeolocation: {
        type: Boolean,
        default: false,
    },
});

const emit = defineEmits(['update:modelValue']);

// Store all selected addresses since select2 can get confused if options are
// reordered and/or deleted.
const selectedAddresses = ref([]);
if (props.modelValue) {
    selectedAddresses.value.push(props.modelValue);
}

// HACK: The select2 component's `modelValue` prop must not be updated before
// the option elements have been rendered in the component's slot. Otherwise
// select2 will ignore the new value.
// We introduce an intermediate ref that stores the modelValue for the select2
// component and we wait a tick before updating the value to allow the options
// to render.
const select2Value = ref(props.modelValue);
watch(
    () => props.modelValue,
    async (addr) => {
        if (!selectedAddresses.value.includes(addr)) {
            selectedAddresses.value.push(addr);
        }
        await nextTick();
        select2Value.value = addr;
    }
);

const select2Config = simpleAutocompleteConfig(null, {
    url: '/api/address/v1/autocomplete',
    transformResponse: (res) => res.suggestions,
    queryKey: 'address',
    valueKey: 'full_address',
    textFormat: '{{full_address}}',
});

const geolocationProvider = inject('geolocationProvider', {
    getGeolocation() {
        return getGeolocation(reverseGeocodeJIX);
    }
});

const waitingForPosition = ref(false);

async function lookupAddress() {
    waitingForPosition.value = true;
    try {
        const pos = await geolocationProvider.getGeolocation();
        emit('update:modelValue', pos.address);
    } catch (err) {
        console.error(err);
        bootbox.alert(i18n.error(err.message));
    } finally {
        waitingForPosition.value = false;
    }
}
</script>

<template>
    <div class="w-100 overflow-hidden">
        <div class="input-group flex-nowrap">
            <div class="flex-grow-1 overflow-hidden">
                <Select2
                    v-bind="$attrs"
                    class="form-control"
                    :config="select2Config"
                    :model-value="select2Value"
                    @update:model-value="emit('update:modelValue', $event)"
                >
                    <option v-for="addr in selectedAddresses" :key="addr" :value="addr">
                        {{ addr }}
                    </option>
                </Select2>
            </div>
            <div v-if="!disableGeolocation" class="input-group-append">
                <button type="button" class="btn btn-secondary address-input__geolocation" @click="lookupAddress">
                    <template v-if="waitingForPosition">
                        <span class="spinner-grow spinner-grow-sm d-block" role="status" aria-hidden="true" />
                        <span class="sr-only">{{ i18n.findingPosition() }}</span>
                    </template>
                    <SvgIcon v-else name="geolocation" :aria-label="i18n.usePosition()" />
                </button>
            </div>
        </div>
    </div>
</template>
