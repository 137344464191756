import { __, __x, __nx, __N } from 'JIX/gettext.js';

export default {
    error: (message) =>
        __x('Fejl: {message}', { message }),
    usePosition: () =>
        __('Brug min placering'),
    findingPosition: () =>
        __('Finder position…'),
};
