<script setup>
import { ref, inject, computed, onMounted, onBeforeUnmount } from 'vue';
import bootbox from 'bootbox';
import { dawaAutocomplete } from 'dawa-autocomplete2';

import { getGeolocation, reverseGeocodeDawa } from 'JIX/geo/geolocation.js';
import SvgIcon from 'JIX/components/SvgIcon.vue';

import i18n from './DsAddressField.i18n.js';

import 'icons/geolocation.svg';

const props = defineProps({
    modelValue: {
        type: String,
        default: "",
    },
    disableGeolocation: {
        type: Boolean,
        default: false,
    },
    accessAddressOnly: {
        type: Boolean,
        default: true,
    },
    variant: {
        type: String,
        default: "light",
        validator: (val) => ['light', 'dark'].includes(val),
    },
});

const emit = defineEmits(['update:modelValue', 'dawa-selected']);

defineOptions({
    inheritAttrs: false,
});

const geolocationProvider = inject('geolocationProvider', {
    getGeolocation() {
        return getGeolocation(reverseGeocodeDawa);
    }
});


const inputListeners = computed(() => {
    return {
        // dawa-autocomplete doesn't trigger an input event when the user
        // clears the input field, so we manually listen for this event.
        // This also overwrites a potential `input` handler from
        // $listeners, which should not be bound to the input element.
        input: (event) => {
            if (event.target.value === "" && props.modelValue) {
                emit('update:modelValue', '');
            }
        },
        // Emit an input event if user defocuses the input field
        // without selecting an autocompletion item.
        blur: (event) => {
            if (event.target.value !== props.modelValue) {
                emit('update:modelValue', event.target.value);
            }
        },
    };
});

const variantBtnClass = computed(() => props.variant === 'light' ? 'btn-secondary' : 'btn-outline-light');

const inputEl = ref();
const instance = ref(null);
onMounted(() => {
    instance.value = dawaAutocomplete(inputEl.value, {
        adgangsadresserOnly: props.accessAddressOnly,
        select: (selected) => {
            emit('update:modelValue', selected.tekst);

            const address = {
                zipcode:     selected.data.postnr,
                city:        selected.data.postnrnavn,
                roadName:    selected.data.vejnavn,
                houseNumber: selected.data.husnr,
                floor:       selected.data.etage,
                door:        selected.data.dør,
                coordinates: { lng: selected.data.x, lat: selected.data.y },
            };
            emit('dawa-selected', address);
        },
    });
});

onBeforeUnmount(() => {
    if (instance.value) {
        instance.value.destroy();
        instance.value = null;
    }
});

const waitingForPosition = ref(false);

function lookupAddress() {
    waitingForPosition.value = true;
    geolocationProvider.getGeolocation()
        .then(
            pos => {
                emit('update:modelValue', pos.address);
            },
            err => {
                console.error(err);
                bootbox.alert(i18n.error(err.message));
            }
        )
        .finally(() => waitingForPosition.value = false);
}
</script>

<template>
    <div class="dawa-autocomplete-container">
        <div class="input-group">
            <input
                v-bind="$attrs"
                v-on="inputListeners"
                ref="inputEl"
                type="text"
                class="form-control"
                :value="modelValue"
            >
            <div v-if="!disableGeolocation" class="input-group-append">
                <button type="button" :class="['btn', variantBtnClass, 'address-input__geolocation']" @click="lookupAddress">
                    <template v-if="waitingForPosition">
                        <span class="spinner-grow spinner-grow-sm d-block" role="status" aria-hidden="true" />
                        <span class="sr-only">{{ i18n.findingPosition() }}</span>
                    </template>
                    <SvgIcon v-else name="geolocation" :aria-label="i18n.usePosition()" />
                </button>
            </div>
        </div>
    </div>
</template>
